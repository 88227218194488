<template>
  <div class="admin-report-by-tag">
    <!-- Page header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Report By Tag
      </h1>
    </div>

    <!-- Search Panel -->
    <div class="search-panel">
      <a-row :gutter="10" align="middle" justify="center" type="flex">
        <a-col :span="21">
          <a-form-item style="margin-bottom: 20px" label="Tag">
            <a-select
              style="width: 100%"
              show-search
              :filter-option="false"
              :loading="isLoadingTags"
              :is-loading="isLoadingTags"
              size="large"
              :disabled="isLoadingResults"
              v-model="selectedTag"
              :options="formattedTags"
              placeholder="Search by tag"
              @search="debouncedSearchTags"
            />
          </a-form-item>
        </a-col>
        <a-col :span="3" style="text-align: right">
          <a-button
            @click.prevent="searchReports"
            class="btn-rounded"
            icon="search"
            size="large"
            type="primary"
            :disabled="!canSearch"
          >Search</a-button>
        </a-col>
      </a-row>
    </div>

    <!-- Loading State -->
    <div v-if="isLoadingResults" class="loader loading-search-results">
      <a-spin></a-spin>
    </div>

    <!-- Results -->
    <div v-else-if="hasSearched" class="search-results">
      <div v-if="formattedIncidents.length === 0" class="no-results">
        <a-empty description="No results found" />
      </div>
      <div v-else class="results-list">
        <a-row :gutter="20" type="flex">
          <a-col :span="24" v-for="incident in formattedIncidents" :key="incident.id">
            <incident-list-item
              :tenant-id="tenantId"
              :incident="incident"
              @selected="viewReport(incident.id)"
            ></incident-list-item>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import tags from '../../api/tags';
import AdminOrgIndicator from '../../components/Admin/AdminOrgIndicator.vue';
import { mapGetters } from 'vuex';
import incidents from '../../api/incidents';
import IncidentListItem from './Incidents/IncidentListItem.vue';

export default {
  name: 'ReportByTag',
  components: {
    AdminOrgIndicator,
    IncidentListItem
  },
  
  data() {
    return {
      isLoading: false,
      isLoadingTags: false,
      isLoadingResults: false,
      selectedTag: null,
      tags: [],
      searchResults: [],
      hasSearched: false,
      debouncedSearchTags: null,
      formattedIncidents: [],
      isLoadingIncidentDetails: false
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }

    this.debouncedSearchTags = debounce(this.searchTags, 300);
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId"
    }),

    formattedTags() {
      return this.tags.map(tag => ({
        value: JSON.stringify({ 
          provider: tag.provider, 
          type: tag.type, 
          providersId: tag.providersId 
        }),
        label: `${tag.description} (${tag.type})`
      }));
    },

    canSearch() {
      return this.selectedTag;
    },
  },

  methods: {
    async searchTags(search) {
      if (!search || search.length < 3) {
        this.tags = [];
        return;
      }
      
      this.isLoadingTags = true;
      try {
        const response = await tags.getTags(
          this.tenantId,
          this.selectedOrganisation.id,
          null,
          search
        );
        this.tags = response.data;
      } catch (e) {
        console.error('Error searching tags:', e);
        this.$message.error('Error loading tags');
      } finally {
        this.isLoadingTags = false;
      }
    },

    async searchReports() {
      if (!this.canSearch) return;

      this.isLoadingResults = true;
      this.hasSearched = true;
      this.searchResults = [];
      this.formattedIncidents = []; // Reset formatted incidents
      
      try {
        const tagData = JSON.parse(this.selectedTag);
        const response = await tags.getItemsByTag(
          this.tenantId,
          this.selectedOrganisation.id,
          'Incident',
          tagData.provider,
          tagData.type,
          tagData.providersId
        );

        this.searchResults = response.data;
        
        // Get full incident details for each result
        this.isLoadingIncidentDetails = true;
        const incidentPromises = response.data.map(async result => {
          try {
            const response = await incidents.getIncident(this.tenantId, result.groupId);
            return response.data;
          } catch (error) {
            if (error.response?.status === 404) {
              // Skip this incident if it's not found
              return null;
            }
            throw error; // Re-throw other errors
          }
        });
        
        const incidentResponses = await Promise.all(incidentPromises);
        // Filter out null responses (404s) and set formatted incidents
        this.formattedIncidents = incidentResponses.filter(incident => incident !== null);
        this.isLoadingIncidentDetails = false;

      } catch (e) {
        console.error('Error searching reports:', e);
        this.$message.error('Error searching reports');
        this.formattedIncidents = [];
      } finally {
        this.isLoadingResults = false;
      }
    },

    getStatusColor(status) {
      const colors = {
        active: 'green',
        closed: 'red',
        pending: 'orange'
        // Add more status colors as needed
      };
      return colors[status.toLowerCase()] || 'default';
    },

    viewReport(id) {
      this.$router.push(`/admin/reports/${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.admin-report-by-tag {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .search-panel {
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .admin-report-list-item {
    margin-bottom: 15px;
  }

  .loading-search-results {
    margin-top: 25px;
  }

  .search-results {
    margin-top: 20px;
  }

  .no-results {
    background: #fff;
    padding: 40px;
    text-align: center;
    border-radius: 6px;
  }

  .button-margin-left {
    margin-left: 15px;
  }
}
</style>